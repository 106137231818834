import React, {useEffect} from "react"

const IndexPage = ({location}) => {
  useEffect(()=>{
    let target = ""
    try {
      const href = location.href
      const origin = location.origin
      target = href.substr(origin.length)
    }catch(e) {
      /* noop */
    }
    document.location.assign(`https://insytepsychiatric.com${target}`)
  }, [])


  return null

}
export default IndexPage
